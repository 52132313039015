import React, { useEffect, useState } from "react"
import Jumbo from "../../components/organisms/jumbo"
import About from "../../components/organisms/about"
import Visualization from "../../components/organisms/visualization"
import Offert from "../../components/organisms/offert"
import Standards from "../../components/organisms/standards"
import Localization from "../../components/organisms/localization"
import Others from "../../components/organisms/others"
import Loading from "../../components/atoms/loading"
import Contact from "../../components/organisms/contact"
import CookieInfo from "../../components/atoms/cookiesInfo"
import { ThemeProvider } from "styled-components"
import { theme } from "../../theme/theme"
import AOS from "aos"
import "aos/dist/aos.css"
import Layout from "../../components/organisms/layout"

const FirstPage = (props) => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (props.location.state !== null) {
      setLoading(false)
    } else {
      document.body.style.overflowY = "hidden"
      setLoading(false)
      document.body.style.overflowY = "scroll"
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Loading display={isLoading} />
      <Layout pageNumber={1}>
        <Jumbo />
        <About />
        <Visualization />
        <Offert />
        <Localization />
        <Standards />
        <Others />
        <Contact />
        <CookieInfo />
      </Layout>
    </ThemeProvider>
  )
}

export default FirstPage
